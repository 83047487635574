<!--
 * @Description: 车行道停车运营管理 员工管理 巡检员管理 chargeManManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <el-row class="topSelectLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="巡检员名称">
              <el-input v-model="searchForm.userName"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="所属街道/城镇">
              <el-select v-model="searchForm.departId"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in streetGroup"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="queryButton">查 询</el-button>

          <el-button type="info"
                     style="margin-left:17px;"
                     @click="resetForm">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>巡检员管理</span>
        <el-button type="info"
                   class="tableTitleButton"
                   style="margin-left:17px"
                   @click="exportFile">导 出</el-button>
        <el-button type="success"
                   class="tableTitleButton"
                   @click="addHandle">新 增</el-button>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  @getList="getList"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @queryTableList="queryTableList">
          <template slot="operating">
            <el-table-column label="所属街道/城镇"
                             width="300px"
                             :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <el-col :span="8">
                  {{streetGroupObj[scope.row.departId]}}
                </el-col>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="巡检员状态">
              <template slot-scope="scope">
                <el-col :span="8">
                  {{scope.row.userStatus | formatStatus}}
                </el-col>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             @click="handleClickDetails(scope.row)">详情</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             @click="editClick(scope.row)">修改</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             @click="deleteClick(scope.row)">删除</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增/修改 -->
    <el-dialog :title="dialogTitle"
               :visible.sync="dialogVisible"
               append-to-body
               @close="popClose">
      <el-form label-width="150px"
               :model="addForm"
               ref="addForm"
               :rules="rules"
               :inline="true"
               size="small">
        <!-- 新增 -->
        <el-row justify="space-around"
                v-if="dialogType == 0">
          <el-col :span="12">
            <el-form-item label="巡检员编号"
                          prop="userCode">
              <el-input v-model="addForm.userCode"
                        placeholder="巡检员编号"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="巡检员名称"
                          prop="userName">
              <el-input v-model="addForm.userName"
                        placeholder="巡检员名称"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 修改 -->
        <el-row justify="space-around"
                v-if="dialogType == 1">
          <el-col :span="12">
            <el-form-item label="巡检员编号"
                          prop="userCode">
              <el-input v-model="addForm.userCode"
                        placeholder="巡检员编号"
                        class="dt-form-width"
                        readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="巡检员名称"
                          prop="userName">
              <el-input v-model="addForm.userName"
                        placeholder="巡检员名称"
                        class="dt-form-width"
                        readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="所属街道/城镇"
                          prop="departId">
              <el-select v-model="addForm.departId"
                         placeholder="所属街道/城镇"
                         class="dt-form-width">
                <el-option v-for="item in streetGroup"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="phoneNumber">
              <el-input v-model="addForm.phoneNumber"
                        placeholder="联系电话"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="性别"
                          prop="userSex">
              <el-select v-model="addForm.userSex"
                         placeholder="请选择性别"
                         class="dt-form-width">
                <el-option v-for="item in sexList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄"
                          prop="userAge">
              <el-input v-model="addForm.userAge"
                        placeholder="请输出年龄"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="handleClickConfirm('addForm')">确 定</el-button>
          <el-button @click="dialogVisible = false"
                     type="info">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog title="巡检员详情"
               :visible.sync="detailDialogVisible"
               append-to-body>
      <el-form label-width="150px"
               :model="detailsForm"
               :inline="true"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="巡检员编号">
              <el-input v-model="detailsForm.userCode"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="巡检员名称">
              <el-input v-model="detailsForm.userName"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="性别">
              <el-input v-model="detailsForm.userSex"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄">
              <el-input v-model="detailsForm.userAge"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="联系电话">
              <el-input v-model="detailsForm.phoneNumber"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属街道">
              <el-input v-model="detailsForm.departName"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="所属巡检组">
              <el-input v-model="detailsForm.inspectionGroupName"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最后点位时间">
              <el-input v-model="detailsForm.reportTime"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="15">
            <el-form-item label="轨迹回放">
              <el-date-picker v-model="replayTime"
                              type="datetimerange"
                              align="right"
                              size="medium"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span='9'>
            <el-button type="primary"
                       @click="handleClickPlay(detailsForm)">播放</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div class="map-container">
        <div id="map"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import Amap from "@/common/js/map.js";
import { mapState } from 'vuex'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      listMap: { pageNum: 1, pageSize: 15 },
      tableList: { list: [] },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableColumnList: [
        {
          prop: 'userName',
          label: '巡检员名称',
        },
        {
          prop: 'phoneNumber',
          label: '联系电话',
        },
        {
          prop: 'inspectionGroupName',
          label: '所属巡检组',
        },
      ],
      searchForm: {
        userName: '',
        departId: '',
      },
      pageNum: 1,
      pageSize: 15,

      streetGroup: [],// 街道列表
      streetGroupObj: {},

      sexList: [
        { code: "男", name: '男' },
        { code: "女", name: '女' }
      ],// 性别

      dialogTitle: '',//弹出框标题
      dialogVisible: false,//弹出框显示
      dialogType: '',// 0新增 1修改

      map: null,
      detailDialogVisible: false,
      detailsForm: {},// 详情列表
      replayTime: null,
      current: [],

      addForm: {
        userName: '',
        userCode: '',
        departId: '',
        phoneNumber: '',
        userAge: '',
        userSex: '',
      },

      rules: {
        userCode: [{ required: true, message: '请输入巡检员编号', trigger: 'blur' },],
        userName: [{ required: true, message: '请输入巡检员名称', trigger: 'blur' },],
        departId: [{ required: true, message: '请选择所属街道/城镇', trigger: 'change' }],
        phoneNumber: [{ required: true, validator: this.publicFun.verifyTel, trigger: 'blur' },],
        userSex: [{ required: true, validator: '请选择性别', trigger: 'change' },],
        userAge: [{ required: true, validator: '请输入年龄', trigger: 'blur' },],
      },
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryStreetGroup()
    this.queryTableList()
  },
  filters: {
    formatStatus (data) {
      if (data === '0') {
        return '待班'
      } else if (data === '1') {
        return '在值'
      }
    },
  },
  //方法集合
  methods: {
    // 导出
    exportFile () {
      this.$axios.get(this.$downloadBaseUrl + "inspectionController/download", {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        }
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "巡检员管理.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    // 查询，获取列表
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$chargeManManagement.queryInspectionList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询按钮
    queryButton () {
      this.pageNum = 1;
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.searchForm = {}
      this.queryTableList()
    },
    // 详情
    handleClickDetails (row) {
      for (let i = 0; i < this.streetGroup.length - 1; i++) {
        if (this.streetGroup[i].code === row.departId) {
          row.departName = this.streetGroup[i].name
        }
      }
      this.queryCurrentDay()
      let info = {
        userCode: row.userCode
      }
      this.$chargeManManagement.getRealtimeLocationByUserCode(info).then(res => {
        if (res.resultEntity) {
          this.detailsForm.reportTime = res.resultEntity.reportTime
          this.$forceUpdate()
        }
      })
      this.detailsForm = row
      this.detailDialogVisible = true
      let that = this
      this.$nextTick(() => {
        that.initMap()
      })
    },
    // 播放
    handleClickPlay (val) {
      let info = {
        userCode: val.userCode,
        start: this.replayTime[0],
        end: this.replayTime[1]
      }
      this.$chargeManManagement.getTrajectory(info).then(res => {
        console.log(res.resultEntity[0], res.resultEntity[0].longitude, res.resultEntity[0].latitude);
        this.map.setCenter(Number(res.resultEntity[0].longitude), Number(res.resultEntity[0].latitude))
        let a = []
        // res.resultEntity.splice(0, 1)
        res.resultEntity.forEach(item => {
          a.push([Number(item.longitude), Number(item.latitude)])
        })
        this.map.setOnlyMarker(a)
      })
    },
    // 初始化地图
    initMap () {
      this.map = new Amap(
        "map",
        [106.562865, 29.600376]
      )
    },
    // 获取当天时间
    queryCurrentDay () {
      let day = new Date()
      let a = day.getFullYear()
      let b = day.getMonth() + 1
      let c = day.getDate()
      if (JSON.stringify(b).length === 1) {
        b = "0" + b
      }
      if ((JSON.stringify(c).length === 1)) {
        c = "0" + c
      }
      this.current[0] = a + "-" + b + "-" + c + " 00:00:00"
      this.current[1] = a + "-" + b + "-" + c + " 23:59:59"
      this.replayTime = this.current
    },
    //新增
    addHandle () {
      this.dialogVisible = true
      this.dialogType = 0
      this.dialogTitle = '新增巡检员'
      this.addForm = {
        userName: '',
        userCode: '',
        departId: '',
        phoneNumber: '',
        userAge: '',
        userSex: '',
      }
    },
    //修改
    editClick (row) {
      this.dialogType = 1
      this.dialogVisible = true
      this.dialogTitle = '修改巡检员信息'
      this.addForm = {
        userName: row.userName,
        userCode: row.userCode,
        departId: row.departId,
        phoneNumber: row.phoneNumber,
        userAge: row.userAge,
        userSex: row.userSex,
      }
    },
    //提交按钮
    handleClickConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType === 0) {
            this.handleAdd()
          } else if (this.dialogType === 1) {
            this.handleEdit()
          }
        } else {
          return false;
        }
      });
    },
    // 新增请求
    handleAdd () {
      this.$chargeManManagement.insertInspection(this.addForm).then(() => {
        this.dialogVisible = false
        this.$message({ type: 'success', message: '新增成功!' });
        this.queryTableList()
      })
    },
    // 修改请求
    handleEdit () {
      this.$chargeManManagement.updateInspection(this.addForm).then(() => {
        this.dialogVisible = false
        this.$message({ type: 'success', message: '修改成功!' });
        this.queryTableList()
      })
    },
    // 删除请求
    deleteClick (row) {
      this.$confirm('是否删除巡检员?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let info = [{
          userCode: row.userCode
        }]
        this.$chargeManManagement.deleteInspection(info).then(() => {
          this.$message({ type: 'success', message: '删除成功!' });
          this.queryTableList()
        })
      })
    },
    // 弹窗关闭
    popClose () {
      this.$refs.addForm.resetFields()
    },
    // 获取城镇街道
    queryStreetGroup () {
      let info = {
        "columnName": ["region_code", "region_name"],
        "tableName": "tb_region"
      }
      this.$queryDict.queryDict(info).then(res => {
        this.streetGroup = res.resultEntity
        this.streetGroup.forEach(item => {
          this.streetGroupObj[item.code] = item.name
        })
      })
    },
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      margin-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
.map-container {
  width: 100%;
  height: 500px;
  #map {
    width: 100%;
    height: 100%;
  }
}
</style>
