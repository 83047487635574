/*
 * @Description: 资源全景-高德地图封装类
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-05-27 14:26:00
 * @LastEditors: zhoucheng
 */

// store
class Amap {
  // 地图实例对象
  map = null
  // 点标记集合
  markerObj = {}
  // 点标记展示的图标集合
  markerIcoObj = {}
  // 信息窗口集合
  infoWindowObj = {}
  infoWindow = null
  themeType = {
    dark: 'amap://styles/a1ef87b8517f7bb18e73e47a65ac69c9',
    light: 'amap://styles/37bc333a00b6dc86dbd9309cae53802f'
  }

  // 点标记点击事件
  clickMark = function () { }
  constructor(dom, center, markerIcoObj, infoWindowObj) {
    this.map = new window.AMap.Map(dom, {
      mapStyle: this.themeType[localStorage.getItem('theme')],
      resizeEnable: true,
      expandZoomRange: true,
      zoom: 17,
      zooms: [3, 20],
      center, // 中心点坐标
      viewMode: '3D',//使用3D视图
      pitch: 60,
    })
    this.markerIcoObj = markerIcoObj
    this.infoWindowObj = infoWindowObj
  }

  // 获取高德地图原生对象
  getAMap () {
    return this.map
  }

  // 设置地图中心点
  setCenter (lng, lat) {
    this.map.setCenter([lng, lat]);
  }

  // 设置地图中心点和缩放级别
  setZoom (zoom) {
    this.map.setZoom(zoom)
  }

  // 设置点标记
  setMarker (id, markDataList) {
    // 如果点标记数组存在直接设置
    let markerList = []
    if (this.getMarkerObj[id]) {
      markerList = this.getMarkerObj(id)
    } else {
      markDataList.forEach(item => {
        let marker = new window.AMap.Marker({
          position: new window.AMap.LngLat(item.longitude, item.latitude),
          offset: new window.AMap.Pixel(-10, -10),
          icon: this.markerIcoObj[id].mapIco, // 添加 Icon 图标 URL
          title: item.title,
          data: item,
          id,
        });
        markerList.push(marker)
        // 绑定点击事件
        let self = this
        marker.on('click', function (ev) {
          let id = ev.target.w.id
          let data = ev.target.w.data
          self.clickMark(id, data)
          self.setInfoWindow(id, data)
        })
      });
      this.setMarkerObj(id, markerList)
    }
    this.map.add(markerList);
  }
  // 轨迹回放设置点标记
  setOnlyMarker (lineArr) {
    const marker = new window.AMap.Marker({
      position: [116.478935, 39.997761],
      icon: require("@/assets/icon/polylinePerson.svg"),
      offset: new window.AMap.Pixel(-15, -60),
      autoRotation: false,
    });
    this.map.add(marker)
    var passedPolyline = new window.AMap.Polyline({
      map: this.map,
      strokeColor: "#AF5",  //线颜色
      strokeWeight: 6,      //线宽
    });
    marker.on('moving', function (e) {
      passedPolyline.setPath(e.passedPath);
    });
    // this.map.setFitView();
    marker.moveAlong(lineArr, 200);
    // return marker
  }

  // 删除点标记
  removeMarker (id) {
    let markerList = this.getMarkerObj(id)
    if (markerList) {
      this.map.remove(markerList)
    }
  }

  // 获取点标记集合
  getMarkerObj (id) {
    return this.markerObj[id]
  }
  // 设置点标记集合
  setMarkerObj (id, markerList) {
    return this.markerObj[id] = markerList
  }

  // 设置信息窗口
  setInfoWindow (id, text) {
    if (!this.infoWindowObj[id]) {
      console.error("InfoWindow.content is " + this.infoWindowObj[id]);
    }
    // 创建 infoWindow 实例	
    this.infoWindow = new window.AMap.InfoWindow({
      isCustom: true,
      anchor: 'middle-right',
      content: this.infoWindowObj[id],  //传入 dom 对象，或者 html 字符串
      offset: new window.AMap.Pixel(-10, 0)
    });
    this.infoWindow.open(this.map, [text.longitude, text.latitude]);
  }
  // 关闭信息窗口
  closeInfoWindow () {
    this.infoWindow.close();
  }
}

export default Amap